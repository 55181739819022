<template>
  <!--经典客户案例-->
  <div class="zj_classicCustom">
    <div class="cnname black font16rem flexcc wow pulse">{{$t('经典客户案例')}}</div>
    <div class="enname black font10rem flexcc wow pulse">
     {{$t('为每一个客户提供行业性、定制化的解决方案')}}
    </div>
    <!-- <div class="classicCustom_part wow bounceInDown black2" -->
    <div
      class="classicCustom_part black2"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div
        class="custom_part flexrow box_shadow2 wow bounceInLeft"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <div class="part_detail">
          <div class="logo font12rem fontweight flextbc marl10">
            <img :src="classicCustomData[0].logo" />
            {{ classicCustomData[0].brand }}
          </div>
          <div class="content font8rem marl10">
            {{ classicCustomData[0].content }}
          </div>
        </div>
        <div class="part_pic">
          <div class="brandpic flexcc">
            <img :src="classicCustomData[0].pic" />
          </div>
        </div>
      </div>
      <div
        class="custom_part flexrow box_shadow2 wow bounceInRight"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <div class="part_pic">
          <div class="brandpic flexcc">
            <img :src="classicCustomData[1].pic" />
          </div>
        </div>
        <div class="part_detail">
          <div class="logo font12rem fontweight flextbc">
            <img style="margin: 0" :src="classicCustomData[1].logo1" />
            <img :src="classicCustomData[1].logo" />
            {{ classicCustomData[1].brand }}
          </div>
          <div class="content font8rem">
            {{ classicCustomData[1].content }}
          </div>
        </div>
      </div>
      <div
        class="custom_part flexrow box_shadow2 wow bounceInLeft"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <div class="part_detail">
          <div class="logo font12rem fontweight flextbc marl10">
            <img :src="classicCustomData[2].logo" />
            {{ classicCustomData[2].brand }}
          </div>
          <div class="content font8rem marl10">
            {{ classicCustomData[2].content }}
          </div>
        </div>
        <div class="part_pic">
          <div class="brandpic flexcc">
            <img :src="classicCustomData[2].pic" />
          </div>
        </div>
      </div>
      <div
          class="custom_part flexrow box_shadow2 wow bounceInRight"
          data-wow-duration="2s"
          data-wow-delay="0s"
          data-wow-offset="10"
          data-wow-iteration="1"
      >
        <div class="part_pic">
          <div class="brandpic flexcc">
            <img :src="classicCustomData[3].pic" />
          </div>
        </div>
        <div class="part_detail">
          <div class="logo font12rem fontweight flextbc">
            <img :src="classicCustomData[3].logo" />
            {{ classicCustomData[3].brand }}
          </div>
          <div class="content font8rem">
            {{ classicCustomData[3].content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  name: "zj_classicCustom",
  data() {
    return {
      classicCustomData: [
        {
          brand: this.$t("安踏"),
          logo: require("../assets/Images/brand/anta.png"),
          content:
            this.$t("为其提供终端POS、终端HR(考勤管理)、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMS（DST&KL）服务，服务品牌有FILA、DESCENTE、KOLON、Sprandi、小笑牛，门店数量超过10000家。将其多个品牌的管理整合在一套系统中，提升了其50%以上的录单效率，100多种业务场景的应用激发销售者参与感，提升工作人员积极性和服务价值，实现多品牌多财务核算体，报表查询效率明显提升。"),
          pic: require("../assets/Images/custom/atpic.png"),
        },
        {
          brand: this.$t("晨光九木杂物社"),
          logo: require("../assets/Images/brand/jmzws.png"),
          logo1: require("../assets/Images/brand/cg.png"),
          content:
             this.$t("晨光九木杂物社是晨光集团下一家中高端文创杂货集合店，全国共有374 家（直营249 家，加盟125 家）。为其提供POS+、DRP、O2O订单路由、电商EB含OMS、移动BI等服务，彻底解决了原先系统运行不稳定、报错率高、操作繁琐等定性和程序健壮因素问题、POS结算效率提升60%以上、ERP报表查询速度控制在了5秒以内，大大提升了其报表查询效率、同时开发及交付效率提升了10倍左右。") ,
          pic: require("../assets/Images/custom/jmzws.jpg"),
        },
        {
          brand:this.$t("太平鸟") ,
          logo: require("../assets/Images/brand/peacebird.png"),
          content:
           this.$t("为其提供多种形式POS、DRP、OMS、CRM、移动BI以及新零售数据中台服务，服务门店超过6000家，提升其门店录单效率60%以上，开发效率是一般工具的10倍左右。") ,
          pic: require("../assets/Images/custom/tpnpic.png"),
        },
        {
          brand: this.$t("波司登"),
          logo: require("../assets/Images/brand/bsd.png"),
          content:
           this.$t("为其提供业务数据中台，移动化项目外包开发服务，数据中台实现云平台、生产、电商、SAP以及HANA数据的大整理，报表开发效率是一般工具8倍，投入是SAP价格的5%。移动化实现了审批、数据化分析包括层级分析、沙盘演示等、以及开关店报店装修工程等移动化管理，我们的开发工具不仅提升了波司登集团的开发效率，还大大节约了其企业信息化的成本。") ,
          pic: require("../assets/Images/custom/bsdpic.png"),
        },
      ],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: false }).init();
  },
  methods: {
    clickTo() {
      console.log("跳转至查看更多页面");
    },
  },
};
</script>

<style scoped lang="scss">
.zj_classicCustom {
  width: 100%;
  height: 100%;
  .cnname {
    width: 100%;
    height: 3rem;
    padding-top: 3rem;
  }
  .enname {
    width: 100%;
    height: 2rem;
  }
  .marl10 {
    margin-left: 10%;
  }
  .classicCustom_part {
    width: 100%;
    height: 85%;
    .custom_part {
      width: 70%;
      height: 24%;
      margin: 3% 15%;
      .part_detail {
        width: 55%;
        height: 100%;
        .logo {
          width: 80%;
          height: 30%;
          img {
            width: 15%;
            margin-right: 5%;
          }
        }
        .content {
          width: 90%;
          height: 70%;
          line-height: 2rem;
        }
      }
      .part_pic {
        width: 45%;
        height: 100%;
        .brandpic {
          width: 100%;
          height: 100%;
          img {
            width: 70%;
          }
        }
      }
    }
  }
}
</style>
