<template>
  <!--客户案例 零售商-->
  <div class="zj_classiccustomerLogo box_shadow1">
    <div
      class="title1 flexcc font16rem wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
    {{$t('助力上千家知名品牌零售商')}}  
    </div>
    <div
      class="title2 flexcc font10rem wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      {{$t('连接品牌、商户和消费者')}}
    </div>
    <div class="customerLogo">
      <div class="logo flexcc wow" v-for="item in logodata" :key="item.index"
      :class="
          item.index <= 4 || (item.index >= 9 && item.index <= 12)
            ? 'bounceInRight'
            : 'bounceInLeft'
        "
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1">
        <img :src="item.pic" />
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
export default {
  name: "zj_classiccustomerLogo",
  data() {
    return {
      logodata: [
        {
          index: 1,
          name: "江苏中和",
          pic: require("../assets/Images/brand/jszh.png"),
        },
        {
          index: 2,
          name: "中盛",
          pic: require("../assets/Images/brand/zs.png"),
        },
        {
          index: 3,
          name: "跃翔",
          pic: require("../assets/Images/brand/yx.png"),
        },
        {
          index: 4,
          name: "河北丽驰",
          pic: require("../assets/Images/brand/hblc.png"),
        },
        {
          index: 5,
          name: "苏州美妙",
          pic: require("../assets/Images/brand/szmm.png"),
        },
        {
          index: 6,
          name: "上海瞰亿",
          pic: require("../assets/Images/brand/shky.png"),
        },
        {
          index: 7,
          name: "英茁体育",
          pic: require("../assets/Images/brand/yzty.png"),
        },
        {
          index: 8,
          name: "汾酒集团",
          pic: require("../assets/Images/brand/fjjt.png"),
        },
        {
          index: 9,
          name: "重点户外",
          pic: require("../assets/Images/brand/zdhw.png"),
        },
        {
          index: 10,
          name: "远拓户外",
          pic: require("../assets/Images/brand/ythw.png"),
        },
        {
          index: 11,
          name: "陕西五环",
          pic: require("../assets/Images/brand/sxwh.png"),
        },
        {
          index: 12,
          name: "宁波奇易加",
          pic: require("../assets/Images/brand/nbqyj.png"),
        },
        {
          index: 13,
          name: "河北宝驰",
          pic: require("../assets/Images/brand/hbbc.png"),
        },
        {
          index: 14,
          name: "上海希捷",
          pic: require("../assets/Images/brand/shxj.png"),
        },
        {
          index: 15,
          name: "大为体育",
          pic: require("../assets/Images/brand/dwty.png"),
        },
        {
          index: 16,
          name: "乌鲁木齐逸品汇",
          pic: require("../assets/Images/brand/wlmqyph.png"),
        },
        {
          index: 17,
          name: "合肥共创",
          pic: require("../assets/Images/brand/hfgc.png"),
        },
        {
          index: 18,
          name: "sprandi",
          pic: require("../assets/Images/brand/sprandi.png"),
        },
        {
          index: 19,
          name: "kolon",
          pic: require("../assets/Images/brand/kolon.png"),
        },
        {
          index: 20,
          name: "徐州宁聚",
          pic: require("../assets/Images/brand/xznj.png"),
        },
        {
          index: 21,
          name: "蓝树贸易",
          pic: require("../assets/Images/brand/lsmy.png"),
        },
      ],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: false }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_classiccustomerLogo {
  width: 70%;
  height: 100%;
  margin: auto;
  .title1 {
    width: 100%;
    height: 4rem;
    padding-top: 2rem;
  }
  .title2 {
    width: 100%;
    height: 2rem;
  }
  .customerLogo {
    width: 95%;
    height: 80%;
    margin-left: 4%;
    .logo {
      width: 22.5%;
      height: 6rem;
      float: left;
      margin: 2% 2.5% 0 0;
      img {
        width: 45%;
      }
    }
  }
}
</style>
