<template>
  <!--客户案例-->
  <div class="home">
    <div class="top">
      <top :selected="selected" :pic="pic"></top>
    </div>
    <div class="topCusIntroduce">
      <topCusIntroduce :icon="icon" :percent="percent" :titleStr="titleStr" :index="index"></topCusIntroduce>
    </div>
    <div class="homeContent">
      <div class="classicCustom" id="classicCustom">
        <classicCustom></classicCustom>
      </div>
      <div class="classiccustomerLogo" id="classiccustomerLogo">
        <classiccustomerLogo></classiccustomerLogo>
      </div>
      <div class="classicDealer" id="classicDealer">
        <classicDealer></classicDealer>
      </div>
      <div class="bottomIntroduce">
        <bottomIntroduce></bottomIntroduce>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import top from "@/components/top.vue";
import topCusIntroduce from "@/components/topCusIntroduce.vue";
import classicCustom from "@/components/classicCustom.vue";
import classiccustomerLogo from "@/components/classiccustomerLogo.vue";
import classicDealer from "@/components/classicDealer.vue";
import bottomIntroduce from "@/components/bottomIntroduce.vue";

export default {
  name: "custom",
  data() {
    return {
      selected: "custom",
      pic: require("../assets/Images/cubg.png"),
      icon: require("../assets/Images/cusbg.png"),
      percent: 60,
      index:0,
      titleStr: [
        {
          id:0,
          name: "客户案例",
          detail: "驱动智慧零售 助力企业成长",
          icon: require("../assets/Images/cusbg.png"),
        }
      ],
    };
  },
  components: {
    top,
    topCusIntroduce,
    classicCustom,
    classiccustomerLogo,
    classicDealer,
    bottomIntroduce,
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
::-webkit-scrollbar-track-piece { //滚动条凹槽的颜色，还可以设置边框属性
background-color:#f8f8f8;
}
::-webkit-scrollbar {//滚动条的宽度
width:9px;
height:9px;
z-index: 999;
}
::-webkit-scrollbar-thumb {//滚动条的设置
background-color:#dddddd;
background-clip:padding-box;
min-height:28px;
-webkit-border-radius: 2em;
 -moz-border-radius: 2em;
 border-radius:2em;
}
::-webkit-scrollbar-thumb:hover {
background-color:#bbb;
}
// ::-webkit-scrollbar {
//   display: none;
// }
.home {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  .top {
    width: 100%;
    height: 3.5rem;
    position: fixed;
    z-index: 100;
  }
  .topCusIntroduce {
    width: 100%;
    height: 37.5rem;
  }
  .homeContent {
    width: 100%;
    //height: 100%;
    background-image: url("../assets/Images/bg.png");
    //background-position: 0%;
    background-repeat: repeat;
    background-size: 100%;
    .classicCustom {
      width: 100%;
      height: 120rem;
    }
    .classiccustomerLogo {
      width: 100%;
      height: 112rem;
      margin-top: 3rem;
    }
    .classicDealer {
      width: 100%;
      height: 54rem;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }
}
</style>